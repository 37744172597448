import { ColumnState, FilterState } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  initialState,
  LocalAppState,
  SessionPreference,
  UserPreference,
} from '../state/local-app-state/local-app-state.reducer';
import { LocalAppStateActions } from '../state/store-types';
import { environment } from 'config/environments/environment';
import { cloneDeep } from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { IJwtDto } from '@chiron/app/shared/dto/auth.dto';
import { RowSpacingType } from '../components/toolbar-component/row-settings-toolbar.component';

const LOCAL_APP_STATE_PREFIX = 'baAppState';

@Injectable({
  providedIn: 'root',
})
export class LocalAppStateService {
  // TODO: (@Talha) Get this from auth service when it's moved to libs
  private readonly _accessToken = localStorage.getItem('baAccessToken');
  private readonly _loggedInUser: string | null = this._accessToken
    ? jwtDecode<IJwtDto>(this._accessToken).sub
    : null;
  constructor(private _store: Store) {}
  saveGridColumnState(gridColumnState: ColumnState[], gridId: string) {
    this._store.dispatch(
      LocalAppStateActions.saveGridColumnState({
        gridColumnState,
        gridId: gridId,
      })
    );
  }
  saveGridColumnStateToLocalstorage(
    gridColumnState: ColumnState[],
    gridId: string
  ) {
    const appStateKey = this._getAppStateKey();
    const localAppState = this.getLocalAppState();
    if (localAppState.gridFilterState) {
      localAppState.gridColumnState[gridId] = gridColumnState;
    } else {
      localAppState.gridColumnState = {
        gridId: [...gridColumnState],
      };
    }
    localStorage.setItem(appStateKey, JSON.stringify(localAppState));
  }
  saveGridFilterState(gridId: string, state: FilterState) {
    this._store.dispatch(
      LocalAppStateActions.saveGridFilterState({
        gridId,
        state,
      })
    );
  }
  saveGridFilterStateToLocalstorage(gridId: string, state: FilterState) {
    const appStateKey = this._getAppStateKey();
    const localAppState = this.getLocalAppState();
    localAppState.gridFilterState[gridId] = state;
    localStorage.setItem(appStateKey, JSON.stringify(localAppState));
  }
  clearGridFilterState(gridId: string) {
    this._store.dispatch(
      LocalAppStateActions.clearGridFilterState({
        gridId,
      })
    );
  }
  clearGridFilterStateFromLocalstorage(gridId: string) {
    const appStateKey = this._getAppStateKey();
    const localAppState = this.getLocalAppState();
    if (localAppState.gridFilterState[gridId]) {
      delete localAppState.gridFilterState[gridId];
      localStorage.setItem(appStateKey, JSON.stringify(localAppState));
    }
  }
  clearGridColumnState(gridId: string) {
    this._store.dispatch(
      LocalAppStateActions.clearGridColumnState({
        gridId,
      })
    );
  }
  clearGridColumnStateFromLocalstorage(gridId: string) {
    const appStateKey = this._getAppStateKey();
    const localAppState = this.getLocalAppState();
    if (localAppState.gridColumnState[gridId]) {
      delete localAppState.gridColumnState[gridId];
      localStorage.setItem(appStateKey, JSON.stringify(localAppState));
    }
  }
  saveViewType(viewType: string, id: string) {
    this._store.dispatch(
      LocalAppStateActions.saveViewType({
        viewType,
        id,
      })
    );
  }
  saveViewTypeToLocalStorage(viewType: string, id: string) {
    const appStateKey = this._getAppStateKey();
    const localAppState = this.getLocalAppState();
    localAppState.viewType[id] = viewType;
    localStorage.setItem(appStateKey, JSON.stringify(localAppState));
  }
  saveAccessChangeComment(comment: string) {
    const appStateKey = this._getAppStateKey();
    const localAppState = this.getLocalAppState();
    localAppState.accessChangeComment = comment;
    localStorage.setItem(appStateKey, JSON.stringify(localAppState));
  }
  updateSessionPreference(key: SessionPreference, value: boolean) {
    const appStateKey = this._getAppStateKey();
    const localAppState = this.getLocalAppState();
    localAppState.sessionPreferences[key] = value;
    localStorage.setItem(appStateKey, JSON.stringify(localAppState));
  }
  updateUserPreference(key: UserPreference, value: boolean) {
    const appStateKey = this._getAppStateKey();
    const localAppState = this.getLocalAppState();
    localAppState.userPreferences[key] = value;
    localStorage.setItem(appStateKey, JSON.stringify(localAppState));
  }
  updateGridSize(gridId: string, size: RowSpacingType) {
    const appStateKey = this._getAppStateKey();
    const localAppState = this.getLocalAppState();
    if (!localAppState.gridRowSpacing) {
      localAppState.gridRowSpacing = {};
    }
    localAppState.gridRowSpacing[gridId] = size;
    localStorage.setItem(appStateKey, JSON.stringify(localAppState));
  }
  updateGridStriping(gridId: string, striping: boolean) {
    const appStateKey = this._getAppStateKey();
    const localAppState = this.getLocalAppState();
    if (!localAppState.gridRowStriping) {
      localAppState.gridRowStriping = {};
    }
    localAppState.gridRowStriping[gridId] = striping;
    localStorage.setItem(appStateKey, JSON.stringify(localAppState));
  }
  getLocalAppState() {
    const appStateKey = this._getAppStateKey();
    const savedState = localStorage.getItem(appStateKey);
    const localAppState: LocalAppState = savedState
      ? JSON.parse(savedState)
      : cloneDeep(initialState);
    return localAppState;
  }
  clearSessionPreferences() {
    Object.keys(localStorage)
      .filter((key) => key.startsWith(LOCAL_APP_STATE_PREFIX))
      .forEach((key) => {
        const localAppState: LocalAppState = JSON.parse(
          localStorage.getItem(key)!
        );
        localAppState.sessionPreferences = initialState.sessionPreferences;
        localStorage.setItem(key, JSON.stringify(localAppState));
      });
  }
  clearStateForNewRelease() {
    Object.keys(localStorage)
      .filter((key) => key.startsWith(LOCAL_APP_STATE_PREFIX))
      .forEach((key) => {
        const localAppState: LocalAppState = JSON.parse(
          localStorage.getItem(key)!
        );
        if (localAppState.release !== environment.release) {
          localStorage.removeItem(key);
        }
      });
  }
  private _getAppStateKey() {
    return `${LOCAL_APP_STATE_PREFIX}-${this._loggedInUser}`;
  }
}
