import { SideBarDef } from '@ag-grid-community/core';

export const BA_MODAL_WIDTH = '500px';
export const INTERNAL_USERNAME_SUFFIX = '@biomedata.io';
export const BIOME_TENANT_ID = 'fce3134bd2da11ed9d2400';
export const MAX_SAVED_IDS = 5;
export const LOCAL_LOGIN_IDS_KEY = 'baLoginIds';
export const URL_REGEX =
  '^https:\\/\\/(([-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6})|(localhost))\\b([-a-zA-Z0-9()@:%_\\+.~#?&\\/\\/=]*)';
export const TITLES: string[] = ['Dr.', 'Mr.', 'Ms.', 'Mrs.'];
export const columnSideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnExpandAll: true,
      },
    },
  ],
  defaultToolPanel: '',
};
export const SROG_PRIORITY_DSMS = ['STS', 'CathPCI', 'TAVR'];
