import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs';
import { LocalAppStateService } from '@shared/lib/services/local-app-state.service';
import { LocalAppStateActions } from '../store-types';
import { SessionPreference, UserPreference } from './local-app-state.reducer';

@Injectable({ providedIn: 'root' })
export class LocalAppStateEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _localAppStateService: LocalAppStateService
  ) {}
  saveViewType$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.saveViewType),
      map((action) => {
        this._localAppStateService.saveViewTypeToLocalStorage(
          action.viewType,
          action.id
        );
        return LocalAppStateActions.saveViewTypeSuccess({
          viewType: action.viewType,
          id: action.id,
        });
      })
    )
  );

  updateGridColumnState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.saveGridColumnState),
      map((action) => {
        this._localAppStateService.saveGridColumnStateToLocalstorage(
          action.gridColumnState,
          action.gridId
        );
        return LocalAppStateActions.saveGridColumnStateSuccess({
          gridColumnState: action.gridColumnState,
          gridId: action.gridId,
        });
      })
    )
  );

  updateGridFilterState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.saveGridFilterState),
      map((action) => {
        this._localAppStateService.saveGridFilterStateToLocalstorage(
          action.gridId,
          action.state
        );
        return LocalAppStateActions.saveGridFilterStateSuccess({
          gridId: action.gridId,
          state: action.state,
        });
      })
    )
  );
  clearGridFilterState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.clearGridFilterState),
      map((action) => {
        this._localAppStateService.clearGridFilterStateFromLocalstorage(
          action.gridId
        );
        return LocalAppStateActions.clearGridFilterStateSuccess({
          gridId: action.gridId,
        });
      })
    )
  );
  clearGridColumnState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.clearGridColumnState),
      map((action) => {
        this._localAppStateService.clearGridColumnStateFromLocalstorage(
          action.gridId
        );
        return LocalAppStateActions.clearGridColumnStateSuccess({
          gridId: action.gridId,
        });
      })
    )
  );
  hideWelcomeBanner$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.hideWelcomeBanner),
      map(() => {
        this._localAppStateService.updateSessionPreference(
          SessionPreference.HideWelcomeBanner,
          true
        );
        return LocalAppStateActions.hideWelcomeBannerSuccess();
      })
    )
  );
  hideMaintenanceNotice$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.hideMaintenanceNotice),
      map(() => {
        this._localAppStateService.updateSessionPreference(
          SessionPreference.HideMaintenanceNotice,
          true
        );
        return LocalAppStateActions.hideMaintenanceNoticeSuccess();
      })
    )
  );
  hideToolTip$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.hideHelpTip),
      map(() => {
        this._localAppStateService.updateSessionPreference(
          SessionPreference.HideHelpTip,
          true
        );
        return LocalAppStateActions.hideHelpTipSuccess();
      })
    )
  );
  hideDisclaimer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.hideDisclaimer),
      map(() => {
        this._localAppStateService.updateSessionPreference(
          SessionPreference.HideDisclaimer,
          true
        );
        return LocalAppStateActions.hideDisclaimerSuccess();
      })
    )
  );
  hidePatientListDisclaimer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.hidePatientListDisclaimer),
      map(() => {
        this._localAppStateService.updateUserPreference(
          UserPreference.HidePatientListDisclaimer,
          true
        );
        return LocalAppStateActions.hidePatientListDisclaimerSuccess();
      })
    )
  );
  hideEchoCasesDisclaimer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.hideEchoCasesDisclaimer),
      map(() => {
        this._localAppStateService.updateUserPreference(
          UserPreference.HideEchoCasesDisclaimer,
          true
        );
        return LocalAppStateActions.hideEchoCasesDisclaimerSuccess();
      })
    )
  );
  hideRiskAuditsDisclaimer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.hideRiskAuditsDisclaimer),
      map(() => {
        this._localAppStateService.updateUserPreference(
          UserPreference.HideRiskAuditsDisclaimer,
          true
        );
        return LocalAppStateActions.hideRiskAuditsDisclaimerSuccess();
      })
    )
  );
  hideDashboardUndoAlert$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.hideDashboardUndoAlert),
      map(() => {
        this._localAppStateService.updateUserPreference(
          UserPreference.HideDashboardUndoAlert,
          true
        );
        return LocalAppStateActions.hideDashboardUndoAlertSuccess();
      })
    )
  );
  clearSessionPreference$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.clearSessionPreferences),
      map(() => {
        this._localAppStateService.clearSessionPreferences();
        return LocalAppStateActions.clearSessionPreferencesSuccess();
      })
    )
  );
  loadLocalAppState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.loadLocalAppState),
      filter((username) => !!username),
      map(() => this._localAppStateService.getLocalAppState()),
      map((localAppState) =>
        LocalAppStateActions.loadLocalAppStateSuccess({
          localAppState,
        })
      )
    )
  );
  saveAccessChangeComment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.saveAccessChangeComment),
      map((action) => {
        this._localAppStateService.saveAccessChangeComment(action.comment);
        return LocalAppStateActions.saveAccessChangeCommentSuccess({
          comment: action.comment,
        });
      })
    )
  );
  updateGridRowSpacing$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.saveGridRowSpacing),
      map((action) => {
        this._localAppStateService.updateGridSize(action.gridId, action.size);
        return LocalAppStateActions.saveGridRowSpacingSuccess({
          gridId: action.gridId,
          size: action.size,
        });
      })
    )
  );
  updateGridRowStriping$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.saveGridRowStriping),
      map((action) => {
        this._localAppStateService.updateGridStriping(
          action.gridId,
          action.striping
        );
        return LocalAppStateActions.saveGridRowStripingSuccess({
          gridId: action.gridId,
          striping: action.striping,
        });
      })
    )
  );
  hideTableauLicenseInfoPanel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocalAppStateActions.hideTableauLicenseInfoPanel),
      map(() => {
        this._localAppStateService.updateSessionPreference(
          SessionPreference.HideTableauLicenseInfoPanel,
          true
        );
        return LocalAppStateActions.hideTableauLicensePanelInfoSuccess();
      })
    )
  );
}
